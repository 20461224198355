.App {
  color: #FFFFFF;
  width: 100%;
  height: 100%;
  background-image: url(./img/bg.png);
  background-size: cover;
  background-position: center bottom;
  background-repeat: repeat-y;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

header {
  background-color: #67a045;
  width: 100%;
  height: 100px;
}

header > img {
  height: 100%;
}

.admin header {
  background: none;
}

.admin {
  background-color: #FFFFFF;
}

footer {
  background-color: #67a045;
  padding: 1vw;
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-shadow: 0px 0px 6px 1px #00000080;
}

footer a {
  font-weight: bold;
  color: #FFFFFF;
}

footer a:focus,
footer a:hover {
  font-weight: bold;
  color: #FFFFFF;
}

#content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  padding-left: 50px;
  padding-right: 10vw;
}

#content .logo-whatsep {
  display: inline-flex;
  width: auto;
  height: 60vh;
  max-height: 60vh;
  height: calc(var(--vh, 1vh) * 60);
  max-height: calc(var(--vh, 1vh) * 60);
  max-width: 40vw;
  margin: 0;
  object-fit: contain;
}

#job-number {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 1.25rem;
}

.App button.bt {
  outline: none;
  border: solid 2px #67a045;
  background: #3894c3;
  background: linear-gradient(90deg, #3894c3 0%, #285a97 100%);
  border-radius: 0.5vw;
  background-size: cover;
  background-repeat: no-repeat;
  width: 15vw;
  min-width: 150px;
  height: 5vw;
  min-height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 1vw;
}

.App button.bt:hover {
  background: #285a97;
  background: linear-gradient(90deg, #307fa7 0%, #234f85 100%);
}

.App button.bt:active {
  background: #67a045;
}

#__ReactUnityWebGL_1__ {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

canvas {
  height: 100% !important;
  flex: 1;
}